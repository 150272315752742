<template>
    <div class="pageV">
        <div v-if="orderDetails" style="padding-top: 20px;background-color: #fff;">
            <img v-if="orderDetails.status == 0" src="../../../../../assets/img/order2.svg" class="image1" />
            <img v-if="orderDetails.status == 1" src="../../../../../assets/img/order3.svg" class="image1" />
            <img v-if="orderDetails.status == 2" src="../../../../../assets/img/order4.svg" class="image1" />
            <div v-if="orderDetails.status == 0" class="text1">
                {{ $t("WaitingReview") }}
            </div>
            <div v-if="orderDetails.status == 1" class="text1">
                {{ $t("Approved") }}
            </div>
            <div v-if="orderDetails.status == 2" class="text1">
                {{ $t("Refuse") }}
            </div>
            <div class="itemV" style="margin-top: 50px;">
                <span class="itemTv1">{{ $t("fbuy.orderno") }}</span>
                <span class="itemTv2">{{ orderDetails.order_sn }}</span>
                <img class="itemIv" src="../../../../../assets/img/copy.svg" @click.prevent="onCopyClick()"
                    :data-clipboard-text="orderDetails.order_sn" />
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("timetime") }}</span>
                <span class="itemTv2">{{ orderDetails.createtime | dateformat }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("identity.regions") }}</span>
                <span v-if="lang === 'en'" class="itemTv2">{{
                    orderDetails.country[0].enname
                }}</span>
                <span v-if="lang === 'ru'" class="itemTv2">{{
                    orderDetails.country[0].runame
                }}</span>
                <span v-if="lang === 'zh'" class="itemTv2">{{
                    orderDetails.country[0].cnname
                }}</span>
                <span v-if="lang === 'fr'" class="itemTv2">{{
                    orderDetails.country[0].frname
                }}</span>
                <span v-if="lang === 'it'" class="itemTv2">{{
                    orderDetails.country[0].itname
                }}</span>
                <span v-if="lang === 'es'" class="itemTv2">{{
                    orderDetails.country[0].esname
                }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("money") }}</span>
                <span class="itemTv2">{{ orderDetails.price }}</span>
            </div>
            <div class="itemV" style="border-bottom: 10px solid #f7f8fa;">
                <span class="itemTv1">{{ $t("Remarks") }}</span>
                <span class="itemTv2">{{ orderDetails.reason }}</span>
            </div>

            <div class="itemV">
                <span class="itemTv1">{{ $t("BnakName") }}</span>
                <span class="itemTv2">{{ orderDetails.bank_name }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("AccountName") }}</span>
                <span class="itemTv2">{{ orderDetails.pay_name }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("AccountNumber") }}</span>
                <span class="itemTv2">{{ orderDetails.bank_number }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("BankCode") }}</span>
                <span class="itemTv2">{{ orderDetails.bank_code }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("RoutingNumber") }}</span>
                <span class="itemTv2">{{ orderDetails.route_code }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("swiftCode") }}</span>
                <span class="itemTv2">{{ orderDetails.swift }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("BankAddress") }}</span>
                <span class="itemTv2">{{ orderDetails.bank_address }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("BranchCode") }}</span>
                <span class="itemTv2">{{ orderDetails.branch_bank_code }}</span>
            </div>
            <div class="itemV">
                <span class="itemTv1">{{ $t("Remarks") }}</span>
                <span class="itemTv2">{{ orderDetails.remark }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { ImagePreview } from 'vant'
import Clipboard from "clipboard";
export default {
    props: {
        id: ""
    },
    data() {
        return {
            lang: localStorage.getItem("lang"),
            orderDetails: "",
        };
    },
    watch: {
        id: {
            handler: function (val, oldVal) {
                this.onLoad();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async onLoad() {
            console.log("id", this.lang);
            const { data } = await this.$http.get(
                "/home/user/withdrawCInfo?id=" + this.id
            );
            if (data) {
                if (data.code === 200) {
                    this.orderDetails = data.data;
                }
            }
        },
        onCopyClick() {
            const clipboard = new Clipboard(".itemIv");
            clipboard.on("success", (e) => {
                this.$toast.success(this.$t("common.success"));
            });
            clipboard.on("error", (e) => {
                console.log("------", JSON.stringify(e));
            });
        },
        previewimg(url) {
            ImagePreview([url])
        },
    },
};
</script>
<style lang="less" scoped>
.pageV {
    height: auto;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
}

.image1 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 80px;
}

.text1 {
    color: #4d5260;
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
}

.itemV {
    display: flex;
    align-items: center;
    border-top: 0.1px solid #f7f8fa;
    height: 45px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.itemV2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.1px solid #f7f8fa;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
}

.itemTv1 {
    color: #9097a7;
    font-size: 15px;
}

.itemTv2 {
    color: #4d5260;
    font-size: 15px;
    flex: 1;
    text-align: end;
}

.itemIv {
    width: 15px;
    height: 15px;
    margin-left: 5px;
}

.itemIv2 {
    width: 100px;
    height: 100px;
}

.addTv {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 3.18rem;
    font-family: "Alibaba PuHuiTi";
    font-style: normal;
    font-weight: 700;
    position: fixed;
    right: 1rem;
    top: 60%;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
  