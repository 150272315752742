import { render, staticRenderFns } from "./c2cDepositDetail.vue?vue&type=template&id=7d8bbb31&scoped=true&"
import script from "./c2cDepositDetail.vue?vue&type=script&lang=js&"
export * from "./c2cDepositDetail.vue?vue&type=script&lang=js&"
import style0 from "./c2cDepositDetail.vue?vue&type=style&index=0&id=7d8bbb31&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d8bbb31",
  null
  
)

export default component.exports