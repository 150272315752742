<template>
    <div class="pager">
        <div class="c2c-title">{{ $t('webAssets.C2Cdeposit') }}</div>
        <div style="padding: 20px;">
            <div class="c2c-note">
                <div class="c2c-note-tips">{{ $t('webAssets.tips') }}</div>
                <div class="c2c-note-content">{{ $t('webAssets.TipsContent') }}</div>
            </div>
            <div class="c2c-body">
                <div class="choose-country">{{ $t('webAssets.CountryArea') }}</div>
                <el-select class="select" v-model="value" :placeholder="$t('webAssets.CountryArea')">
                    <template slot="prefix" v-if="value">
                        <div style="display: flex;align-items: center;height: 100%;">
                            <img class="itemTv1" :src="onIconWith(value)" />
                        </div>
                    </template>
                    <el-option v-for="(item, key) in options" :key="key" :label="onLangName(item, true)" :value="item.id">

                        <div
                            style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <img style="width: 40px;height: 25px;min-width: 40px;min-height: 25px;" :src="item.icon" />
                                <span class="itemTv2" style="margin-left: 10px;">
                                    {{ onLangName(item) }}
                                </span>
                            </div>
                            <span class="itemTv2">
                                +{{ item.code }}
                            </span>
                        </div>

                    </el-option>
                </el-select>
                <div class="select" style="margin-top: 20px; display: flex;align-items: center;">

                    <input class="v-input" v-model="amount" :placeholder="$t('webAssets.TransactionAmount')"
                        oninput="this.value=this.value.replace(/[^\d.]/g,'').replace(/(\.\d+)\.+/g, '$1')">
                </div>

                <van-button class="submit" style="border: none;" :loading="loading" :loading-text="$t('jia-zai-zhong')"
                    @click="onSubmit">
                    {{ $t('common.submit') }}
                </van-button>

            </div>
        </div>
        <van-dialog v-model:show="isWaitSecond" :showConfirmButton="false" closeOnClickOverlay>

            <div class="wait">
                <div class="animation-box">
                    <img src="../../../../../assets/img/2.gif" alt="" style="width: 80px;height: 80px;">
                </div>
                <span style="margin:0px 20px 20px 20px; font-size: 12px;">{{ $t('WaitSecond') }}</span>
            </div>

        </van-dialog>
    </div>
</template>

<script>
export default {

    data() {
        return {
            lang: localStorage.getItem("lang"),
            options: [],
            value: "",
            amount: "",
            isWaitSecond: false,
            loading: false
        }
    },
    async created() {
        const { data } = await this.$http.get("/home/user/checkRecharge");
        if (data) {
            if (data.code === 40001) {

                this.$message({
                    showClose: true,
                    message: this.$t("PaymentOrderNotCompleted"),
                    type: 'error'
                });
                this.$emit("reverse", {
                    id: 12,
                    index: 2
                });
            }
        }
        this.onLoadData();
    },
    methods: {
        onIconWith(id) {
            const item = this.options.find(item => item.id === id);
            return item.icon;
        },
        onLangName(item, isCode = false) {

            switch (this.lang) {
                case 'en':
                    return isCode ? item.enname + ' +' + item.code : item.enname;
                case 'ru':
                    return isCode ? item.runame + ' +' + item.code : item.runame;
                case 'zh':
                    return isCode ? item.cnname + ' +' + item.code : item.cnname;
                case 'fr':
                    return isCode ? item.frname + ' +' + item.code : item.frname;
                case 'it':
                    return isCode ? item.itname + ' +' + item.code : item.itname;
                case 'es':
                    return isCode ? item.esname + ' +' + item.code : item.esname;
                default:
                    return isCode ? item.enname + ' +' + item.code : item.enname;
            }
        },
        async onLoadData() {
            const { data } = await this.$http.get("/home/home/getcodelist");
            if (data) {
                if (data.code === 200) {
                    this.options = data.data;
                }
            }

        },
        async onSubmit() {
            if (this.value === "" || this.value === null || this.value === undefined) {

                return this.$message.error(this.$t('webAssets.CountryArea'));
            }
            if (this.amount === "" || this.amount === null || this.amount === undefined) {

                return this.$message.error(this.$t('webAssets.TransactionAmount'));
            }
            const { data } = await this.$http.get("/home/user/checkRecharge");
            if (data) {
                if (data.code === 200) {
                    this.onSubmitDeposit(this.value, this.amount);
                } else if (data.code === 40001) {

                    this.$message({
                        showClose: true,
                        message: this.$t("PaymentOrderNotCompleted"),
                        type: 'error'
                    });
                    this.$emit("reverse", {
                        id: 12,
                        index: 2
                    });
                }
            }
        },
        async onSubmitDeposit(id, price) {

            const postform = {
                c_id: id,
                price: price
            };
            this.loading = true;
            const { data } = await this.$http.post("/home/user/rechargeC", postform);
            this.loading = false;
            if (data) {
                if (data.code === 200) {
                    this.isWaitSecond = true;
                    setTimeout(() => {
                        this.isWaitSecond = false;
                        this.$emit("reverse", {
                            id: 12,
                            index: 2
                        });
                    }, 30000);
                } else {
                    this.$message({
                        showClose: true,
                        message: data.msg,
                        type: 'error'
                    });
                }
            }
        }

    }
}
</script>

<style lang="less" scoped>
.pager {
    min-height: 100%;
    height: 200px;
    width: 100%;

    .c2c-title {
        padding-left: 20px;
        height: 50px px;
        line-height: 50px;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
        text-align: left;
        border-bottom: 1px solid #2b3446;
    }

    .c2c-note {
        max-width: 564px;
    }

    .c2c-note-tips {
        font-size: 16px;
        line-height: 22px;
        color: #f0b90a;
        text-align: left;
    }

    .c2c-note-content {
        margin-top: 5px;
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
        color: #9097a7;
    }

    .c2c-body {
        max-width: 370px;

        .choose-country {
            width: 100%;
            margin: 32px 0 8px 0;
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
            color: #9097a7;
        }

    }

    .select {
        width: 100%;
        height: 56px;
        background: #111a2c;

        .v-input {
            width: 100%;
            height: 40px;
            background: none;
            border: none;
            font-size: 14px;
            margin-left: 50px;
            color: #fff;

        }

        .v-input::input-placeholder {
            color: #ffffffb3;
        }

        .v-input::-webkit-input-placeholder {
            //兼容WebKit browsers（Chrome的内核）
            color: #ffffffb3;
        }

        .v-input::-moz-placeholder {
            //Mozilla Firefox 4 to 18
            color: #ffffffb3;
        }

        .v-input::-moz-placeholder {
            //Mozilla Firefox 19+
            color: #ffffffb3;
        }

        .v-input::-ms-input-placeholder {
            //Internet Explorer 10+
            color: #ffffffb3;
        }
    }

    ::v-deep .el-input__inner {
        height: 56px;
        background: #111a2c;
        border: none;
        color: #fff;
        padding-left: 50px;
    }

    .itemTv1 {
        width: 40px;
        height: 25px;
    }

    .submit {
        margin-top: 20px;
        width: 100%;
        height: 36px;
        background: #f0b90a;
        border-radius: 4px;
        line-height: 36px;
        color: #fff;
        cursor: pointer;
    }
}

.wait {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.animation-box {
    display: flex;
    height: 80px;
    width: 80px;
    justify-content: space-between;

}
</style>