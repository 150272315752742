<template>
    <div class="pager">
        <div v-if="isCountryArea">
            <div class="c2c-title">{{ $t('C2CWithdrawal') }}</div>
            <div style="padding: 20px;">
                <div class="c2c-note">
                    <div class="c2c-note-tips">{{ $t('webAssets.tips') }}</div>
                    <div class="c2c-note-content">{{ $t('c2cTisp') }}</div>
                </div>
                <div class="c2c-body">
                    <div class="choose-country">{{ $t('webAssets.CountryArea') }}</div>
                    <el-select class="select" v-model="value" :placeholder="$t('webAssets.CountryArea')">
                        <template slot="prefix" v-if="value">
                            <div style="display: flex;align-items: center;height: 100%;">
                                <img class="itemTv1" :src="onIconWith(value)" />
                            </div>
                        </template>
                        <el-option v-for="(item, key) in options" :key="key" :label="onLangName(item, true)"
                            :value="item.id">

                            <div
                                style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <img style="width: 40px;height: 25px;min-width: 40px;min-height: 25px;"
                                        :src="item.icon" />
                                    <span class="itemTv2" style="margin-left: 10px;">
                                        {{ onLangName(item) }}
                                    </span>
                                </div>
                                <span class="itemTv2">
                                    +{{ item.code }}
                                </span>
                            </div>

                        </el-option>
                    </el-select>
                    <div class="submit1" @click="onConfimCountryArea">{{ $t('common.submit') }}</div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="c2c-title">{{ $t('C2CWithdrawal') }}</div>
            <div style="padding: 20px;">
                <div class="c2c-note">
                    <div class="c2c-note-tips">{{ $t('webAssets.tips') }}</div>
                    <div class="c2c-note-content">{{ $t('C2CWithdrawalTips') }}</div>
                </div>
            </div>
            <div class="c2c-body">

                <div class="item">
                    <div>{{ $t('BnakName') + '*' }}</div>
                    <input :placeholder="$t('BnakName')" v-model="info.bank_name">
                </div>
                <div class="item">
                    <div>{{ $t('AccountName') + '*' }}</div>
                    <input :placeholder="$t('AccountName')" v-model="info.pay_name">
                </div>
                <div class="item">
                    <div>{{ $t('AccountNumber') + '*' }}</div>
                    <input :placeholder="$t('AccountNumber')" v-model="info.bank_number">
                </div>
                <div class="item">
                    <div>{{ $t('BankCode') }}</div>
                    <input :placeholder="$t('BankCode')" v-model="info.bank_code">
                </div>
                <div class="item">
                    <div>{{ $t('RoutingNumber') }}</div>
                    <input :placeholder="$t('RoutingNumber')" v-model="info.route_code">
                </div>
                <div class="item">
                    <div>{{ $t('swiftCode') }}</div>
                    <input :placeholder="$t('swiftCode')" v-model="info.swift">
                </div>
                <div class="item">
                    <div>{{ $t('BankAddress') }}</div>
                    <input :placeholder="$t('BankAddress')" v-model="info.bank_address">
                </div>
                <div class="item">
                    <div>{{ $t('BranchCode') }}</div>
                    <input :placeholder="$t('BranchCode')" v-model="info.branch_bank_code">
                </div>
                <div class="item">
                    <div>{{ $t('Remarks') }}</div>
                    <input :placeholder="$t('Remarks')" v-model="info.remark">
                </div>
                <div class="bottom">
                    <div class="top">
                        <div class="title">{{ $t('Withdrawal') }} {{ $t('Amount') }}</div>
                        <span class="amount">{{ $t('Available') }} {{ available }} </span>
                    </div>

                    <div class="input-v">
                        <input type="digit" v-model="info.price" :placeholder="$t('PleaseEnterTheAmount')" />
                        <span class="divider" />
                        <span @click="info.price = available" style="margin: 0 30px;">{{ $t('All') }}</span>
                    </div>

                </div>
                <van-button class="submit" style="border: none;" :loading="loading" :loading-text="$t('jia-zai-zhong')"
                    @click="onSubmit">
                    {{ $t('common.submit') }}
                </van-button>


            </div>


        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            lang: localStorage.getItem("lang"),
            options: [],
            value: "",
            isCountryArea: true,
            loading: false,
            info: {
                price: "",// 金额,
                bank_name: "", // 银行名称
                pay_name: "", // 收款人姓名
                bank_number: "", // 银行卡号
                bank_code: "", // 银行代码
                route_code: "", // 路由代码
                swift: "", // swift
                bank_address: "", // 银行地址
                branch_bank_code: "", // 分行代码
                remark: "", // 备注
            },
            available: "0.00000",
        }
    },
    created() {
        this.onLoadData();
    },
    methods: {
        onIconWith(id) {
            const item = this.options.find(item => item.id === id);
            return item.icon;
        },
        onLangName(item, isCode = false) {

            switch (this.lang) {
                case 'en':
                    return isCode ? item.enname + ' +' + item.code : item.enname;
                case 'ru':
                    return isCode ? item.runame + ' +' + item.code : item.runame;
                case 'zh':
                    return isCode ? item.cnname + ' +' + item.code : item.cnname;
                case 'fr':
                    return isCode ? item.frname + ' +' + item.code : item.frname;
                case 'it':
                    return isCode ? item.itname + ' +' + item.code : item.itname;
                case 'es':
                    return isCode ? item.esname + ' +' + item.code : item.esname;
                default:
                    return isCode ? item.enname + ' +' + item.code : item.enname;
            }
        },
        async onLoadData() {
            const { data } = await this.$http.get("/home/home/getcodelist");
            if (data) {
                if (data.code === 200) {
                    this.options = data.data;
                }
            }
            const res = await this.$http.get("/home/user/index");
            if (res.data) {
                if (res.data.code === 200) {

                    const { usable = '0.00000' } = res.data.data.balance;
                    this.available = usable;
                }
            }

        },
        onConfimCountryArea() { // 确认区域
            if (this.value === "" || this.value === null || this.value === undefined) {
                return this.$message.error(this.$t('webAssets.CountryArea'));
            }
            this.isCountryArea = false
        },
        async onSubmit() {
            if (this.info.bank_name === "") {
                return this.$toast(this.$t('PleaseEnterTheBankName'));
            }
            if (this.info.pay_name === "") {
                return this.$toast(this.$t('PleaseEnterTheAccountName'));
            }
            if (this.info.bank_number === "") {
                return this.$toast(this.$t('PleaseEnterTheAccountNumber'));
            }
            if (this.info.price === "") {
                return this.$toast(this.$t('PleaseEnterTheAmount'));
            }

            const params = Object.assign({ c_id: this.value }, this.info);
            this.loading = true;
            const { data } = await this.$http.post("/home/user/withdrawC", params);
            this.loading = false;
            if (data) {
                if (data.code === 200) {
                    this.$toast(this.$t('C2CWithdrawalSubmitSuccess'));
                    this.$emit("reverse", {
                        id: 12,
                        index: 3
                    });
                } else {
                    this.$toast(data.msg);
                }
            }
        },

    }
}
</script>

<style lang="less" scoped>
.pager {
    min-height: 100%;
    height: 200px;
    width: 100%;

    .c2c-title {
        padding-left: 20px;
        height: 50px px;
        line-height: 50px;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
        text-align: left;
        border-bottom: 1px solid #2b3446;
    }

    .c2c-note {
        max-width: 564px;
    }

    .c2c-note-tips {
        font-size: 16px;
        line-height: 22px;
        color: #f0b90a;
        text-align: left;
    }

    .c2c-note-content {
        margin-top: 5px;
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
        color: #9097a7;
    }

    .c2c-body {
        max-width: 370px;

        .choose-country {
            width: 100%;
            margin: 32px 0 8px 0;
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
            color: #9097a7;
        }

    }

    .select {
        width: 100%;
        height: 56px;
        background: #111a2c;

        .v-input {
            width: 100%;
            height: 40px;
            background: none;
            border: none;
            font-size: 14px;
            margin-left: 50px;
            color: #fff;

        }

        .v-input::input-placeholder {
            color: #ffffffb3;
        }

        .v-input::-webkit-input-placeholder {
            //兼容WebKit browsers（Chrome的内核）
            color: #ffffffb3;
        }

        .v-input::-moz-placeholder {
            //Mozilla Firefox 4 to 18
            color: #ffffffb3;
        }

        .v-input::-moz-placeholder {
            //Mozilla Firefox 19+
            color: #ffffffb3;
        }

        .v-input::-ms-input-placeholder {
            //Internet Explorer 10+
            color: #ffffffb3;
        }
    }

    ::v-deep .el-input__inner {
        height: 56px;
        background: #111a2c;
        border: none;
        color: #fff;
        padding-left: 50px;
    }

    .itemTv1 {
        width: 40px;
        height: 25px;
        min-width: 40px;
        min-height: 25px;

    }

    .item {
        margin: 10px 0 0 20px;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        text-align: right;
        width: 564px;
        border-radius: 5px;
        border: 1px solid #ffffffb3;

        div {
            color: #ffffffb3;
        }

        input {
            flex: 1;
            height: 34px;
            background: none;
            border: none;
            text-align: right;
            color: #fff;
        }

        .input::input-placeholder {
            color: #ffffffb3;
        }

        .input::-webkit-input-placeholder {
            //兼容WebKit browsers（Chrome的内核）
            color: #ffffffb3;
        }

        .input::-moz-placeholder {
            //Mozilla Firefox 4 to 18
            color: #ffffffb3;
        }

        .input::-moz-placeholder {
            //Mozilla Firefox 19+
            color: #ffffffb3;
        }

        .input::-ms-input-placeholder {
            //Internet Explorer 10+
            color: #ffffffb3;
        }
    }

    .submit1 {
        margin-top: 20px;
        width: 100%;
        height: 36px;
        background: #f0b90a;
        border-radius: 4px;
        line-height: 36px;
        color: #fff;
        cursor: pointer;
    }

    .submit {
        margin-left: 20px;
        margin-top: 20px;
        width: 564px;
        height: 36px;
        background: #f0b90a;
        border-radius: 4px;
        line-height: 36px;
        color: #fff;
        cursor: pointer;
    }

    .bottom {

        margin: 20px 0 0 20px;
        width: 564px;

        .top {
            display: flex;
            justify-content: space-between;

            .title {

                color: #bfbfbf;
            }

            .amount {
                color: #bfbfbf;
            }
        }

        .input-v {
            display: flex;
            height: 50px;
            padding: 13px;
            margin: 10px 0;
            border: 0.5px solid #e5e5e5;
            border-radius: 3px;

            input {
                flex: 1;
                border: none;
                background: none;
                color: #fff;
            }
        }



        .divider {
            width: 1px;
            height: 80%;
            margin: 0;
            background: #bfbfbf;
        }

    }

}
</style>